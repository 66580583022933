import { FC, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "src/plugins/store/store.hooks";
import { localizationThunks } from "../../state/localization.slice";
import Icon from "@ant-design/icons";
import './localization-changer.css';

import { ReactComponent as moonSvg } from "./icons/moon.svg";
import { ReactComponent as sunSvg } from "./icons/sun.svg";
import { LocalizationKeyType } from "../../state/localization.types";
import { KitSelectInput } from "src/kit/select-input/kit-select-input";

import styles from "./localization-changer.module.css";
import i18next from "i18next";

export const LocalizationChanger: FC = () => {
  const dispatch = useAppDispatch();

  const breakpoint = useAppSelector(state=>state.adaptiveData.breakpoint);

  const selectedKey = useAppSelector(
    (state) => state.localizationData.selectedKey
  );

  const changeLocalization = (key: LocalizationKeyType) => {
    dispatch(localizationThunks.changeLocalization(key));
  };

  const isInit = useAppSelector((state) => state.localizationData.isInit);
  const enabled = useAppSelector((state) => state.localizationData.enabled);

  const languages = useMemo(() => {
    return enabled.map((lang) => {
      const langTranslation = i18next?.store?.data?.[lang]?.language as any;
      return {
        value: lang,
        label: breakpoint === 'xs' ? <div className={styles.optionXs}><img src={`/images/flags/${lang}.png`} /></div> : <div className={styles.option}><img src={`/images/flags/${lang}.png`} /><p>{langTranslation?.name}</p></div>,
      };
    }).filter(lang=>lang.label);
  }, [i18next.store.data, breakpoint]);

  return (
    <div className={`${styles.container} localization_changer`}>
      <KitSelectInput
        changeHandler={(e: any) => {
          changeLocalization(e as LocalizationKeyType);
        }}
        width={breakpoint === 'xs' ? "60px" : "130px"}
        value={selectedKey}
        flagPrefix={selectedKey}
        options={languages}
      />
    </div>
  );
};
