import i18next from "i18next";
import { AppThunk } from "src/plugins/store/store";
import { localizationActions } from "../state/localization.slice";
import {
  ILocalizations,
  LocalizationKeyType,
} from "../state/localization.types";
import { LOCALIZATION } from "./localization.thunks.async";

const changeLocalizations =
  (localizations: ILocalizations): AppThunk =>
  (dispatch) => {
    dispatch(localizationActions.setLocalizations(localizations));
  };
export const localizationThunksCallbacksSync = {
  changeLocalizations,
};
