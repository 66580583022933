import { FC } from "react";
import { useAppSelector } from "src/plugins/store/store.hooks";
import { IThemeProviderProps } from "./theme-provider.types";

export const ThemeProvider: FC<IThemeProviderProps> = ({ children }) => {
  const theme = useAppSelector((state) => state.themeData.theme);

  return (
    <>
      <link
        rel="stylesheet"
        type="text/css"
        href={`./css-themes/theme.${theme}.css`}
      />
      {children}
    </>
  );
};
