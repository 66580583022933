import { FC, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAppSelector } from "../store/store.hooks";
import { IRoute, IRouterProps } from "./router.types";

export const Router: FC<IRouterProps> = ({ routes }) => {

  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
        <Routes>
          {routes.map((route: IRoute) => {
            return (
              <Route key={route.path} path={route.path} element={route.page} />
            );
          })}
        </Routes>
    </>
  );
};
