import { ConfigProvider } from "antd";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { PagesController } from "./pages/controller";
import { AdaptiveProvider } from "./plugins/adaptive/wrapper/adaptive-provider/adaptive-provider";
import { LocalizationProvider } from "./plugins/localization/wrapper/localization-provider";
import { StoreProvider } from "./plugins/store/store-provider";
import { ThemeProvider } from "./plugins/theme/wrappers/theme-provider/theme-provider";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <ConfigProvider
    theme={{
      token: {
        fontFamily: 'Alef'
      },
    }}
  >
    <StoreProvider>
      <LocalizationProvider>
        <ThemeProvider>
          <AdaptiveProvider>
            <BrowserRouter>
              <PagesController />
            </BrowserRouter>
          </AdaptiveProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </StoreProvider>
  </ConfigProvider>
);
