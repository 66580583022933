import { PayloadAction } from "@reduxjs/toolkit";
import { BreakpointsEnum, IAdaptiveState, IMaxWidths } from "./adaptive.types";

function setBreakpoint(
  state: IAdaptiveState,
  { payload: breakpoint }: PayloadAction<BreakpointsEnum>
) {
  state.breakpoint = breakpoint;
}
export const adaptiveReducers = {
  setBreakpoint,
};
