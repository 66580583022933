import React, { FC } from "react";
import { Router } from "src/plugins/router/router";
import { ContactsPage } from "./contacts/contacts";
import { HomePage } from "./home/home";

const routes = [
  {
    path: "/",
    page: <HomePage />,
  },
  {
    path: "/contacts",
    page: <ContactsPage />,
  },
];

export const PagesController: FC = () => {
  return <Router routes={routes} />;
};
