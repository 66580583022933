import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationChanger } from "src/plugins/localization/views/localization-changer/localization-changer";
import styles from "./contacts.module.css";
import { HomeFilled, PhoneFilled, MailFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export const ContactsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // console.log({ location });
  }, [location]);
  const { t } = useTranslation();
  const socials = useMemo(() => {
    return [
      {
        src: "/images/contacts/fb.svg",
        link: t("footer.facebook_url"),
      },
      {
        src: "/images/contacts/twitter.svg",
        link: t("footer.twitter_url"),
      },
      {
        src: "/images/contacts/inst.svg",
        link: t("footer.instagram_url"),
      },
      {
        src: "/images/contacts/linked.svg",
        link: t("footer.linked_url"),
      },
    ];
  }, [i18next.language]);
  return (
    <div className={styles.container}>
      <img className={styles.map} src="images/home/map.png" />
      <div className={styles.line}></div>
      <div className={styles.line_transparent}>
        <div className={styles.contacts}>
          <div className={styles.contact}>
            <PhoneFilled />
            <a href={`tel:${t("header.phone")}`}><p style={{ marginTop: "2px" }}>{t('header.phone')}</p></a>
              <a
                target={"_blank"}
                href={`https://wa.me/${t("contacts.spain.phone_value")
                  ?.replaceAll("+", "")
                  .replaceAll(" ", "")}`}
              >
                <img src="images/contacts/whatsapp.svg" />
              </a>
          </div>
          <div className={styles.contact}>
            <MailFilled />
            <p>{t('header.email')}</p>
          </div>
          <div className={styles.socials}>
              {socials.map((social) => (
                <div>
                  <a target={"_blank"} href={social.link}>
                    <img src={social.src} className={social.src === '/images/contacts/linked.svg' ? styles.linkedinIcon : ''} />
                  </a>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className={styles.header}>
        <div className={styles.language}>
          <LocalizationChanger />
        </div>
        <div className={styles.navigation}>
          <div
            className={`${styles.menu_button}`}
            onClick={() => {
              navigate("/");
              window.location.hash = "";
            }}
          >
            <p>{t("header.home")}</p>
          </div>
          <div className={styles.menu_button}>
            <p
              onClick={() => {
                // scrollToRef(servicesScrollRef);
                navigate("/#services");
              }}
            >
              {t("header.services")}
            </p>
          </div>
          <div className={styles.menu_button}>
            <p
              onClick={() => {
                // scrollToRef(partnersScrollRef);
                navigate("/");
                window.location.hash = "clients";
              }}
            >
              {t("header.partners")}
            </p>
          </div>
          <div className={`${styles.menu_button} ${styles.selected}`}>
            <p>{t("header.contacts")}</p>
          </div>
        </div>
      </div>
      <div className={styles.title}>
        <p>{t("contacts.title")}</p>
      </div>
      <div className={styles.cards}>
        <div className={styles.cards}>
          <div className={styles.card}>
            <p className={styles.name}>{t("contacts.spain.name")?.toLowerCase()}</p>
            <img className={styles.flag} src="/images/contacts/flag_es.svg" />
            <div className={styles.contact_line}>
              <strong className={styles.description}>
              {t("contacts.spain.description")}
              </strong>
            </div>
            <div className={styles.contact_line}>
            <div className={styles.description}><HomeFilled /></div>
              <p className={styles.description}>
              {t("contacts.spain.address_value")}
              </p>
            </div>
            {t("contacts.spain.phone_value") ? <div className={styles.contact_line}>
            <div className={styles.description}><PhoneFilled /></div>
              <a href={`tel:${t("contacts.spain.phone_value")}`}><p className={styles.description}>{t("contacts.spain.phone_value")}</p></a>
              <a target={'_blank'} href={`https://wa.me/${t('contacts.spain.phone_value')?.replaceAll('+','').replaceAll(' ','')}`}><img src="images/contacts/whatsapp.svg" /></a>
            </div> : null}
            {t("contacts.spain.email_value") ? <div className={styles.contact_line}>
            <div className={styles.description}><MailFilled /></div>
              <p className={styles.description}>{t("contacts.spain.email_value")}</p>
            </div> : null}
          </div>
          <div className={styles.card}>
            <p className={styles.name}>{t("contacts.turkey.name")?.toLowerCase()}</p>
            <img className={styles.flag} src="/images/contacts/flag_tr.svg" />
            <div className={styles.contact_line}>
              <strong className={styles.description}>
              {t("contacts.turkey.description")}
              </strong>
            </div>
            <div className={styles.contact_line}>
            <div className={styles.description}><HomeFilled /></div>
              <p className={styles.description}>
              {t("contacts.turkey.address_value")}
              </p>
            </div>
            {t("contacts.turkey.phone_value") ? <div className={styles.contact_line}>
              <div className={styles.description}><PhoneFilled /></div>
              <a href={`tel:${t("contacts.turkey.phone_value")}`}><p className={styles.description}>{t("contacts.turkey.phone_value")}</p></a>
              <a target={'_blank'} href={`https://wa.me/${t('contacts.turkey.phone_value')?.replaceAll('+','').replaceAll(' ','')}`}><img src="images/contacts/whatsapp.svg" /></a>
            </div> : null}
           {t("contacts.turkey.email_value") ?  <div className={styles.contact_line}>
           <div className={styles.description}><MailFilled /></div>
              <p className={styles.description}>{t("contacts.turkey.email_value")}</p>
            </div> : null}
          </div>
          <div className={styles.card}>
            <p className={styles.name}>{t("contacts.china.name")?.toLowerCase()}</p>
            <img className={styles.flag} src="/images/contacts/flag_cn.svg" />
            <div className={styles.contact_line}>
              <strong className={styles.description}>
              {t("contacts.china.description")}
              </strong>
            </div>
            <div className={styles.contact_line}>
            <div className={styles.description}><HomeFilled /></div>
              <p className={styles.description}>
              {t("contacts.china.address_value")}
              </p>
            </div>
            {t("contacts.china.phone_value") ? <div className={styles.contact_line}>
            <div className={styles.description}><PhoneFilled /></div>
              <a href={`tel:${t("contacts.china.phone_value")}`}><p className={styles.description}>{t("contacts.china.phone_value")}</p></a>
              <a target={'_blank'} href={`https://wa.me/${t('contacts.china.phone_value')?.replaceAll('+','').replaceAll(' ','')}`}><img src="images/contacts/whatsapp.svg" /></a>
            </div> : null}
            {t("contacts.china.email_value") ? <div className={styles.contact_line}>
            <div className={styles.description}><MailFilled /></div>
              <p className={styles.description}>{t("contacts.china.email_value")}</p>
            </div> : null}
          </div>
          <div className={styles.card}>
            <p className={styles.name}>{t("contacts.usa.name")}</p>
            <img className={styles.flag} src="/images/contacts/flag_us.svg" />
            <div className={styles.contact_line}>
              <strong className={styles.description}>
              {t("contacts.usa.description")}
              </strong>
            </div>
            <div className={styles.contact_line}>
            <div className={styles.description}><HomeFilled /></div>
              <p className={styles.description}>
              {t("contacts.usa.address_value")}
              </p>
            </div>
            {t("contacts.usa.phone_value") ? <div className={styles.contact_line}>
            <div className={styles.description}><PhoneFilled /></div>
              <a href={`tel:${t("contacts.usa.phone_value")}`}><p className={styles.description}>{t("contacts.usa.phone_value")}</p></a>
              <a target={'_blank'} href={`https://wa.me/${t('contacts.usa.phone_value')?.replaceAll('+','').replaceAll(' ','')}`}><img src="images/contacts/whatsapp.svg" /></a>
            </div> : null}
            {t("contacts.usa.email_value") ? <div className={styles.contact_line}>
            <div className={styles.description}><MailFilled /></div>
              <p className={styles.description}>{t("contacts.usa.email_value")}</p>
            </div> : null}
          </div>
        </div>
      </div>
    </div>
  );
};
