import { FC, useEffect } from "react";
import { useAppDispatch } from "src/plugins/store/store.hooks";
import { adaptiveThunks } from "../../state/adaptive.slice";
import { IAdaptiveProviderProps } from "./adaptive-provider.types";

export const AdaptiveProvider: FC<IAdaptiveProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const calculateWindowBreakpoint = () => {
    dispatch(adaptiveThunks.calculateWindowBreakpoint(window.innerWidth));
  };
  useEffect(() => {
    calculateWindowBreakpoint();

    window.addEventListener(
      "resize",
      calculateWindowBreakpoint.bind(AdaptiveProvider)
    );
    return () => {
      window.removeEventListener(
        "resize",
        calculateWindowBreakpoint.bind(AdaptiveProvider)
      );
    };
  }, []);
  return children;
};
