import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/plugins/store/store";
import { createThunksForExport } from "src/plugins/store/store.services";
import { LOCALIZATION, localizationThunksCallbacksAsync } from "../logic/localization.thunks.async";
import { localizationThunksCallbacksSync } from "../logic/localization.thunks.sync";
import { localizationReducers } from "./localization.actions";
import { localizationInitialState } from "./localization.state";

export const localizationThunks: any = createThunksForExport({
  async: localizationThunksCallbacksAsync,
  sync: localizationThunksCallbacksSync,
});

export const localizationSlice = createSlice({
  name: "localizationData",
  initialState: localizationInitialState,
  reducers: localizationReducers,
});

export const localizationActions = localizationSlice.actions;

export const selectLocalizationData = (state: RootState) => state.localizationData;

export default localizationSlice.reducer;