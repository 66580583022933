import i18next, { i18n, TFunction } from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  ILocalizationState,
  LocalizationKeyType,
} from "../state/localization.types";
import { localizationActions } from "../state/localization.slice";

const initLocalStorageInstance = async (_: any, thunkApi: any) => {
  const { localizations, selectedKey } = thunkApi.getState()
    .localizationData as ILocalizationState;

  const enabled = [];
  try {
    const t: any = JSON.parse(
      localStorage.getItem("enabled") || '{"enabled": ["es"]}'
    );
    enabled.push(...t.enabled);
  } catch (e) {
    console.log(e);
  }

  thunkApi.dispatch(localizationActions.setEnabled(enabled));
  console.log("local enabled", enabled);

  const translations: any = {};

  await Promise.all(
    enabled.map(async (t) => {
      try {
        const content: any = JSON.parse(
          localStorage.getItem(`translation_${t}_content`) ||
            `{
            "language": {
              "name": "español"
            },
            "header": {
              "phone": "",
              "email": "",
              "home": "INICIO",
              "services": "SERVICIOS",
              "partners": "SOCIOS",
              "contacts": "CONTACTOS",
              "menu": "menu"
            },
            "logo": {
              "subtitle": "LOGISTICA INTERNATIONAL"
            },
            "main": {
              "title": "SERVICIOS LOGISTICOS COMPLETOS",
              "paragraph_1": "Somos una empresa internacional en desarrollo dinámico en el mercado de la logística. Nuestro equipo está formado por especialistas responsables y proactivos con muchos años de experiencia en el sector del transporte.",
              "paragraph_2": "Brindamos un complejo completo de servicios logísticos como transitario con nuestras propias oficinas y una amplia red de agencias en diferentes países del mundo y nuestra moderna infraestructura tecnológica."
            },
            "logistics": {
              "title": "",
              "rail": "",
              "air": "",
              "road": "",
              "sea": ""
            },
            "services": {
              "title": "",
              "forwarding": "",
              "forwarding_description": "",
              "customs": "",
              "customs_description": "",
              "agency": "",
              "agency_description": "",
              "related": "",
              "related_description": ""
            },
            "geo": {
              "located_in": "",
              "country_1": "",
              "country_2": "",
              "country_3": "",
              "country_4": "",
              "and": "",
              "description": ""
            },
            "clients": {
              "title": ""
            },
            "footer": {
              "description": "",
              "address_key": "",
              "address_value": "",
              "phone_key": "",
              "phone_value": "",
              "email_key": "",
              "email_value": "",
              "twitter_url": "",
              "instagram_url": "",
              "facebook_url": "",
              "linked_url": ""
            },
            "contacts": {
              "title": "",
              "spain": {
                "name": "",
                "description": "",
                "address_key": "",
                "address_value": "",
                "phone_key": "",
                "phone_value": "",
                "email_key": "",
                "email_value": ""
              },
              "turkey": {
                "name": "",
                "description": "",
                "address_key": "",
                "address_value": "",
                "phone_key": "",
                "phone_value": "",
                "email_key": "",
                "email_value": ""
              },
              "china": {
                "name": "china",
                "description": "",
                "address_key": "",
                "address_value": "",
                "phone_key": "",
                "phone_value": "",
                "email_key": "",
                "email_value": ""
              },
              "usa": {
                "name": "USA",
                "description": "",
                "address_key": "",
                "address_value": "",
                "phone_key": "",
                "phone_value": "",
                "email_key": "",
                "email_value": ""
              }
            }
          }`
        );
        console.log("local content", content);
        translations[t] = content;
      } catch (e) {
        console.log(e);
      }
    })
  );

  Object.entries(translations).forEach(([key, value]) => {
    translations[key].translation = value;
  });

  LOCALIZATION.t = await i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: translations,
      fallbackLng: "en",
      lng: selectedKey,
      debug: false,
      returnEmptyString: true,

      interpolation: {
        escapeValue: false,
      },
    });
  // thunkApi.dispatch(localizationActions.setLocalizations(translations));

  thunkApi.dispatch(localizationActions.setIsInit(true));
};

const changeInstance = async (_: any, thunkApi: any) => {
  const { localizations, selectedKey } = thunkApi.getState()
    .localizationData as ILocalizationState;

  const enabled = [];
  try {
    const t: any = await (
      await fetch("translation/config.json", {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
    ).json();
    enabled.push(...t.enabled);
    localStorage.setItem("enabled", JSON.stringify({ enabled }));
  } catch (e) {
    console.log(e);
  }

  thunkApi.dispatch(localizationActions.setEnabled(enabled));
  console.log("server enabled", enabled);

  const translations: any = {};

  await Promise.all(
    enabled.map(async (t) => {
      try {
        const content: any = await (
          await fetch(`translation/${t}.json`, {
            headers: {
              "Cache-Control": "no-cache",
            },
          })
        ).json();
        console.log("server content", content);
        localStorage.setItem(
          `translation_${t}_content`,
          JSON.stringify(content)
        );
        translations[t] = content;
      } catch (e) {
        console.log(e);
      }
    })
  );

  Object.entries(translations).forEach(([key, value]) => {
    translations[key].translation = value;
  });

  LOCALIZATION.t = await i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: translations,
      fallbackLng: "en",
      lng: selectedKey,
      debug: false,
      returnEmptyString: true,
      interpolation: {
        escapeValue: false,
      },
    });
  // thunkApi.dispatch(localizationActions.setLocalizations(translations));

  thunkApi.dispatch(localizationActions.setIsInit(true));
};

const changeLocalization = async (key: LocalizationKeyType, thunkApi: any) => {
  await thunkApi.dispatch(localizationActions.setSelectedKey(key));
  localStorage.setItem("language", key);
  await i18next.changeLanguage(key);
};

export const localizationThunksCallbacksAsync = {
  initLocalStorageInstance,
  changeInstance,
  changeLocalization,
};

export const LOCALIZATION: {
  t: TFunction<"translation", undefined>;
  isInit: boolean;
} = {
  t: {} as TFunction<"translation", undefined>,
  isInit: false,
};
