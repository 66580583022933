import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import adaptiveDataReducers from "src/plugins/adaptive/state/adaptive.slice";
import themeDataReducers from "src/plugins/theme/state/theme.slice";
import localizationDataReducers from "src/plugins/localization/state/localization.slice";
//DO_NOT_DELETE_THIS_STRING__IMPORT

export const store = configureStore({
  reducer: {
    adaptiveData: adaptiveDataReducers,
    themeData: themeDataReducers,
    localizationData: localizationDataReducers,
    //DO_NOT_DELETE_THIS_STRING__REDUCER
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
