import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/plugins/store/store";
import { createThunksForExport } from "src/plugins/store/store.services";
import { adaptiveThunksCallbacksAsync } from "../logic/adaptive.thunks.async";
import { adaptiveThunksCallbacksSync } from "../logic/adaptive.thunks.sync";
import { adaptiveReducers } from "./adaptive.actions";
import { adaptiveInitialState } from "./adaptive.state";

export const adaptiveThunks: any = createThunksForExport({
  async: adaptiveThunksCallbacksAsync,
  sync: adaptiveThunksCallbacksSync,
});

export const adaptiveSlice = createSlice({
  name: "adaptiveData",
  initialState: adaptiveInitialState,
  reducers: adaptiveReducers,
});

export const adaptiveActions = adaptiveSlice.actions;

export const selectAdaptiveData = (state: RootState) => state.adaptiveData;

export default adaptiveSlice.reducer;
