import { AppThunk } from "src/plugins/store/store";
import { adaptiveActions } from "../state/adaptive.slice";
import { BreakpointsEnum } from "../state/adaptive.types";

const calculateWindowBreakpoint =
  (width: number): AppThunk =>
  (dispatch, getState) => {
    const { maxWidths, breakpoint } = getState().adaptiveData;
    const calculatedBreakpoint: BreakpointsEnum =
      width < maxWidths.xs
        ? BreakpointsEnum.xs
        : width < maxWidths.sm
        ? BreakpointsEnum.sm
        : width < maxWidths.md
        ? BreakpointsEnum.md
        : BreakpointsEnum.lg;

    if (calculatedBreakpoint === breakpoint) return;
    dispatch(adaptiveActions.setBreakpoint(calculatedBreakpoint));
  };

export const adaptiveThunksCallbacksSync = {
  calculateWindowBreakpoint,
};
