import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./services.module.css";

export const Services :FC<{scrollRef: any}>= ({scrollRef}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container} ref={scrollRef}>
      <div className={styles.title}>
        <p>{t('services.title')}</p>
      </div>
      <div className={styles.cards}>
        <div className={styles.cards}>
          <div className={styles.card}>
            <p className={styles.name}>{t('services.forwarding')}</p>
            <img src="/images/services/forwarding.png" />
            <p className={styles.description}>{t('services.forwarding_description')}</p>
          </div>
          <div className={styles.card}>
            <p className={styles.name}>{t('services.customs')}</p>
            <img src="/images/services/customs.png" />
            <p className={styles.description}>{t('services.customs_description')}</p>
          </div>
          <div className={styles.card}>
            <p className={styles.name}>{t('services.agency')}</p>
            <img src="/images/services/agency.png" />
            <p className={styles.description}>{t('services.agency_description')}</p>
          </div>
          <div className={styles.card}>
            <p className={styles.name}>{t('services.related')}</p>
            <img src="/images/services/related.png" />
            <p className={styles.description}>{t('services.related_description')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
