import { PayloadAction } from "@reduxjs/toolkit";
import {
  ILocalizations,
  ILocalizationState,
  LocalizationKeyType,
} from "./localization.types";

function setSelectedKey(
  state: ILocalizationState,
  { payload: selectedKey }: PayloadAction<LocalizationKeyType>
) {
  state.selectedKey = selectedKey;
}
function setLocalizations(
  state: ILocalizationState,
  { payload: localizations }: PayloadAction<ILocalizations>
) {
  state.localizations = localizations;
}
function setEnabled(
  state: ILocalizationState,
  { payload: enabled }: PayloadAction<string[]>
) {
  state.enabled = enabled;
}
function setIsInit(
  state: ILocalizationState,
  { payload: isInit }: PayloadAction<boolean>
) {
  state.isInit = isInit;
}

export const localizationReducers = {
  setSelectedKey,
  setLocalizations,
  setEnabled,
  setIsInit,
};
