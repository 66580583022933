import i18next, { i18n } from "i18next";
import React, { FC, useEffect } from "react";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { useAppDispatch, useAppSelector } from "src/plugins/store/store.hooks";
import { localizationThunks } from "../state/localization.slice";

let init = false;

export const LocalizationProvider: FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const isInit = useAppSelector((state) => state.localizationData.isInit);
  const localizations = useAppSelector(
    (state) => state.localizationData.localizations
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (init) return;
    init = true;
    if (!localizations) return;
    dispatch(localizationThunks.initLocalStorageInstance());
    dispatch(localizationThunks.changeInstance());
  }, [localizations]);

  return <>{isInit ? children : null}</>;
};
