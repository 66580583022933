import { AppThunk } from "src/plugins/store/store";
import { themeActions } from "../state/theme.slice";

const activateLightTheme = (): AppThunk => (dispatch, getState) => {
  dispatch(themeActions.setTheme("light"));
  sessionStorage.setItem("theme", "light");
};
const activateDarkTheme = (): AppThunk => (dispatch, getState) => {
  dispatch(themeActions.setTheme("dark"));
  sessionStorage.setItem("theme", "dark");
};

export const themeThunksCallbacksSync = {
  activateLightTheme,
  activateDarkTheme,
};
