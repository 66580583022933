import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/plugins/store/store";
import { createThunksForExport } from "src/plugins/store/store.services";
import { themeThunksCallbacksAsync } from "../logic/theme.thunks.async";
import { themeThunksCallbacksSync } from "../logic/theme.thunks.sync";
import { themeReducers } from "./theme.actions";
import { themeInitialState } from "./theme.state";

export const themeThunks: any = createThunksForExport({
  async: themeThunksCallbacksAsync,
  sync: themeThunksCallbacksSync,
});

export const themeSlice = createSlice({
  name: "themeData",
  initialState: themeInitialState,
  reducers: themeReducers,
});

export const themeActions = themeSlice.actions;

export const selectThemeData = (state: RootState) => state.themeData;

export default themeSlice.reducer;
