import { PayloadAction } from "@reduxjs/toolkit";
import { IThemeState, ThemeType } from "./theme.types";

function setTheme(
  state: IThemeState,
  { payload: theme }: PayloadAction<ThemeType>
) {
  state.theme = theme;
}
export const themeReducers = {
  setTheme,
};
