import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./clients.module.css";

const clients = [
  "aqseptence", "antec", "aquasystem",
  "buhlmann", "av", "dynapac",
  "generent",  "trouvay", "prosilicon",
];
export const Clients :FC<{scrollRef: any}> = ({scrollRef}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container} ref={scrollRef}>
      <div className={styles.title}>
        <p>{t('clients.title')}</p>
      </div>
      <div className={styles.card}>
        <div className={styles.clients}>
          {clients.map((clientName) => (
            <div className={styles.client} key={clientName}>
              <img src={`./images/clients/${clientName}.jpg`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
