import { Button, Space } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { LocalizationChanger } from "src/plugins/localization/views/localization-changer/localization-changer";
import styles from "./home.module.css";
import { PhoneFilled, MailFilled } from "@ant-design/icons";
import { Logistics } from "./modules/logistics/logistics";
import { Services } from "./modules/services/services";
import { Geo } from "./modules/geo/geo";
import { Clients } from "./modules/clients/clients";
import { Contacts } from "./modules/contacts/contacts";
import { useLocation, useNavigate } from "react-router-dom";
import { useScrollPosition } from "src/plugins/scroll/hook";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useAppSelector } from "src/plugins/store/store.hooks";

const rotations = [60, 150, 240, 330];

let autoSlideInterval: any;

let slide = 1;

function startAutoSlide(setSlide: any) {
  if (!autoSlideInterval) {
    autoSlideInterval = setInterval(() => {
      slide = slide + 1;
      setSlide(slide);
    }, 3500);
  }
}

function stopAutoSlide() {
  clearInterval(autoSlideInterval);
  autoSlideInterval = undefined;
}

export const HomePage = () => {
  const breakpoint = useAppSelector(state => state.adaptiveData.breakpoint)
  const [currentSide, setCurrentSide] = useState(3);
  const [rotateY, setRotateY] = useState(rotations[3]);

  useEffect(() => {
    if (breakpoint === 'xs') {
      // stopAutoSlide()
      setRotateY(-30);
    } else {
      // startAutoSlide(setSlide);
    }
  }, [breakpoint])

  const moveLeft = (multiply?: number) => {
    const nextRotate = rotateY - 90 * (multiply || 1);
    setRotateY(nextRotate);
    const nextSide =
      (nextRotate > 0 ? nextRotate : 360 + (nextRotate % 360)) % 330 === 0
        ? 3
        : (nextRotate > 0 ? nextRotate : 360 + (nextRotate % 360)) % 240 === 0
          ? 2
          : (nextRotate > 0 ? nextRotate : 360 + (nextRotate % 360)) % 150 === 0
            ? 1
            : 0;
    setCurrentSide(nextSide);
  };

  const moveRight = (multiply?: number) => {
    const nextRotate = rotateY + 90 * (multiply || 1);
    setRotateY(nextRotate);
    const nextSide =
      (nextRotate > 0 ? nextRotate : 360 + (nextRotate % 360)) % 330 === 0
        ? 3
        : (nextRotate > 0 ? nextRotate : 360 + (nextRotate % 360)) % 240 === 0
          ? 2
          : (nextRotate > 0 ? nextRotate : 360 + (nextRotate % 360)) % 150 === 0
            ? 1
            : 0;
    setCurrentSide(nextSide);
  };

  const [slide, setSlide] = useState(1);

  useEffect(() => {
    moveLeft();
  }, [slide]);

  useEffect(() => {
    startAutoSlide(setSlide);
    return () => {
      stopAutoSlide();
    };
  }, []);

  const moveToSelect = (nextSide: number) => {
    stopAutoSlide();
    if (nextSide > currentSide) {
      if (nextSide === 3 && currentSide === 0) {
        moveLeft();
      } else {
        if (
          (nextSide === 2 && currentSide === 0) ||
          (nextSide === 3 && currentSide === 1)
        ) {
          moveRight(2);
        } else {
          moveRight();
        }
      }
    } else if (nextSide < currentSide) {
      if (nextSide === 0 && currentSide === 3) {
        moveRight();
      } else {
        if (
          (nextSide === 0 && currentSide === 2) ||
          (nextSide === 1 && currentSide === 3)
        ) {
          moveLeft(2);
        } else {
          moveLeft();
        }
      }
    }
  };

  const mainScrollRef = useRef({} as any);
  const logisticsScrollRef = useRef({} as any);
  const servicesScrollRef = useRef({} as any);
  const clientsScrollRef = useRef({} as any);
  const scrollToRef = (ref: any, instant?: boolean) => {
    ref.current.scrollIntoView({
      behavior: instant ? "instant" : "smooth",
    });
  };

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#services") {
      scrollToRef(servicesScrollRef, true);
    } else if (location.hash === "#clients") {
      scrollToRef(clientsScrollRef, true);
    } else {
      window.location.hash = "";
    }
  }, []);

  const [showBurger, setShowBurger] = useState(false);
  const [showHeader, setShowHeader] = useState(false);

  const [page, setPage] = useState("main");

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y > -800) {
      setShowBurger(false);
      setShowHeader(false);
    } else {
      setShowBurger(true);
      setShowHeader(true);
    }

    if (currPos.y > -1700) {
      setPage("main");
      window.location.hash = "main";
      startAutoSlide(setSlide);
    } else if (currPos.y <= -1700 && currPos.y > -2300) {
      setPage("services");
      window.location.hash = "services";
      stopAutoSlide();
    } else {
      setPage("clients");
      window.location.hash = "clients";
      stopAutoSlide();
    }
  });

  const { t } = useTranslation();
  const socials = useMemo(() => {
    return [
      {
        src: "/images/contacts/fb.svg",
        link: t("footer.facebook_url"),
      },
      {
        src: "/images/contacts/twitter.svg",
        link: t("footer.twitter_url"),
      },
      {
        src: "/images/contacts/inst.svg",
        link: t("footer.instagram_url"),
      },
      {
        src: "/images/contacts/linked.svg",
        link: t("footer.linked_url"),
      },
    ];
  }, [i18next.language]);

  return (
    <div>
      <div className={styles.container} ref={mainScrollRef}>
        <img className={styles.map} src="images/home/map.png" />
        <div className={styles.line}></div>
        <div className={styles.line_transparent}>
          <div className={styles.contacts}>
            <div className={styles.contact}>
              <PhoneFilled />
              <a href={`tel:${t("header.phone")}`}><p style={{ marginTop: "2px" }}>{t("header.phone")}</p></a>
              <a
                target={"_blank"}
                href={`https://wa.me/${t("contacts.spain.phone_value")
                  ?.replaceAll("+", "")
                  .replaceAll(" ", "")}`}
              >
                <img src="images/contacts/whatsapp.svg" />
              </a>
            </div>
            <div className={styles.contact}>
              <MailFilled />
              <p>{t("header.email")}</p>
            </div>
            <div className={styles.socials}>
              {socials.map((social) => (
                <div>
                  <a target={"_blank"} href={social.link}>
                    <img src={social.src} className={social.src === '/images/contacts/linked.svg' ? styles.linkedinIcon : ''} />
                  </a>
                </div>
              ))}
            </div>
          </div>

          <div
            className={styles.burger}
            style={{ display: showBurger ? "" : "none" }}
            onClick={() => {
              setShowHeader((prev) => !prev);
            }}
          >
            <p>{t("header.menu")}</p>
            <img src="/burger.svg" />
          </div>
        </div>
        {/* <div className={styles.card}>
          
      </div> */}

        {breakpoint !== 'xs' ?
          <>
            <div className={styles.carousel_container}>
              <div
                className={styles.carousel}
                style={{
                  transform: `
            scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(${rotateY}deg)
            rotateZ(0deg) translateX(0) translateY(0px) translateZ(-85px) skewX(0deg)
            skewY(0deg)
        `,
                }}
              >
                <div
                  className={`${styles.carousel_side} ${styles.carousel_front}`}
                  style={{ filter: currentSide !== 3 ? "saturate(2)" : "saturate(2)" }}
                >
                  <img src="/images/home/road.jpg" />
                </div>
                <div
                  className={`${styles.carousel_side} ${styles.carousel_left}`}
                  style={{ filter: currentSide !== 0 ? "saturate(2)" : "saturate(2)" }}
                >
                  <img src="/images/home/sea.jpg" />
                </div>
                <div
                  className={`${styles.carousel_side} ${styles.carousel_back}`}
                  style={{ filter: currentSide !== 1 ? "saturate(1.3)" : "saturate(1.3)" }}
                >
                  <img src="/images/home/air.jpg" />
                </div>
                <div
                  className={`${styles.carousel_side} ${styles.carousel_right}`}
                  style={{ filter: currentSide !== 2 ? "saturate(2)" : "saturate(2)" }}
                >
                  <img src="/images/home/rail.jpg" />
                </div>
                <div
                  className={`${styles.carousel_side} ${styles.carousel_top}`}
                ></div>
                <div
                  className={`${styles.carousel_side} ${styles.carousel_bottom}`}
                ></div>
              </div>
            </div>

            <div className={styles.carousel_controls}>
              <div
                className={`${styles.carousel_circle} ${currentSide === 0 ? styles.selected : null
                  }`}
                onClick={() => {
                  moveToSelect(0);
                }}
              ></div>
              <div
                className={`${styles.carousel_circle} ${currentSide === 1 ? styles.selected : null
                  }`}
                onClick={() => {
                  moveToSelect(1);
                }}
              ></div>
              <div
                className={`${styles.carousel_circle} ${currentSide === 2 ? styles.selected : null
                  }`}
                onClick={() => {
                  moveToSelect(2);
                }}
              ></div>
              <div
                className={`${styles.carousel_circle} ${currentSide === 3 ? styles.selected : null
                  }`}
                onClick={() => {
                  moveToSelect(3);
                }}
              ></div>
            </div>

            <div className={styles.logoContainer}>
              <div className={styles.logo}>
                <img src="./images/home/logo.png" />
              </div>
              <div className={styles.tag}>
                <p>{t("logo.subtitle")}</p>
              </div>
            </div>
            <div className={styles.title}>
              <p>{t("main.title")}</p>
              <div className={styles.about}>
                <p>{t("main.paragraph_1")}</p>
                <p>{t("main.paragraph_2")}</p>
              </div>
            </div>
            {/* <div className={styles.about_title}>
        <p>About Us</p>
      </div> */}
            {/* <Button type="primary" className={styles.button}>
        <p>Our Contacts</p>
      </Button>
      <Button type="default" className={styles.details_button}>
        <p>Details</p>
      </Button> */}
          </>
          :

          <>
            <div className={styles.mobileHome}>
              <div className={styles.slider}>
                <img className={`${styles.sliderImage} ${currentSide === 0 ? styles.sliderCurrent : null}`} src={`/images/home/road.jpg`} />
                <img className={`${styles.sliderImage} ${currentSide === 1 ? styles.sliderCurrent : null}`} src={`/images/home/air.jpg`} />
                <img className={`${styles.sliderImage} ${currentSide === 2 ? styles.sliderCurrent : null}`} src={`/images/home/sea.jpg`} />
                <img className={`${styles.sliderImage} ${currentSide === 3 ? styles.sliderCurrent : null}`} src={`/images/home/rail.jpg`} />


                {/* <div className={styles.logoContainer}>
              <div className={styles.logo}>
                <img src="./images/home/logo_texted.jpg" />
              </div>
            </div> */}
              </div>
              <div className={styles.content}>
                <div className={styles.logo}>
                  <img src="./images/home/logo.png" />
                </div>
                <div className={styles.tag}>
                  <p>{t("logo.subtitle")}</p>
                </div>
                <div className={styles.title}>
                  <p>{t("main.title")}</p>
                  <div className={styles.about}>
                    <p>{t("main.paragraph_1")}</p>
                    <p>{t("main.paragraph_2")}</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        <div className={styles.header}>
          <div className={styles.language}>
            <LocalizationChanger />
          </div>
          <div className={styles.navigation}>
            <div
              className={`${styles.menu_button} ${page === "main" ? styles.selected : null
                }`}
            >
              <p>{t("header.home")}</p>
            </div>
            <div
              className={`${styles.menu_button} ${page === "services" ? styles.selected : null
                }`}
            >
              <p
                onClick={() => {
                  scrollToRef(servicesScrollRef);
                }}
              >
                {t("header.services")}
              </p>
            </div>
            <div
              className={`${styles.menu_button} ${page === "clients" ? styles.selected : null
                }`}
            >
              <p
                onClick={() => {
                  scrollToRef(clientsScrollRef);
                }}
              >
                {t("header.partners")}
              </p>
            </div>
            <div
              className={styles.menu_button}
              onClick={() => {
                navigate("/contacts");
                window.location.hash = "";
              }}
            >
              <p>{t("header.contacts")}</p>
            </div>
          </div>
        </div>

        <div
          className={styles.hidable_header}
          style={{ display: showHeader ? "" : "none" }}
        >
          <div className={styles.header}>
            <div className={styles.language}>
              <LocalizationChanger />
            </div>
            <div className={styles.navigation}>
              <div
                className={`${styles.menu_button} ${page === "main" ? styles.selected : null
                  }`}
                onClick={() => {
                  scrollToRef(mainScrollRef);
                }}
              >
                <p>{t("header.home")}</p>
              </div>
              <div
                className={`${styles.menu_button} ${page === "services" ? styles.selected : null
                  }`}
              >
                <p
                  onClick={() => {
                    scrollToRef(servicesScrollRef);
                  }}
                >
                  {t("header.services")}
                </p>
              </div>
              <div
                className={`${styles.menu_button} ${page === "clients" ? styles.selected : null
                  }`}
              >
                <p
                  onClick={() => {
                    scrollToRef(clientsScrollRef);
                  }}
                >
                  {t("header.partners")}
                </p>
              </div>
              <div
                className={styles.menu_button}
                onClick={() => {
                  navigate("/contacts");
                }}
              >
                <p>{t("header.contacts")}</p>
              </div>
            </div>
          </div>
        </div>

        <div
          className={styles.arrow}
          onClick={() => {
            scrollToRef(logisticsScrollRef);
          }}
        >
          <img src="arrow.svg" />
        </div>
      </div>

      <Logistics scrollRef={logisticsScrollRef} />
      <Services scrollRef={servicesScrollRef} />
      <Geo />
      <Clients scrollRef={clientsScrollRef} />
      <Contacts />
    </div>
  );
};
