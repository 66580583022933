import { Select, Space } from "antd";
import React, { FC, useEffect, useRef } from "react";
import { IKitSelectInputProps } from "./kit-select-input.types";
import "./kit-select-input.css";
import { DeleteTwoTone } from "@ant-design/icons";

export const KitSelectInput: FC<IKitSelectInputProps> = ({
  defaultValue,
  changeHandler,
  options,
  width,
  height,
  titleWidth,
  title,
  value,
  allowClear,
  flagPrefix,
}) => {

  return (
      <Space
        direction="horizontal"
        size="large"
        className={`kit-select-input`}
      >
        {title ? (
          <p style={{ minWidth: titleWidth || "60px" }}>{title}</p>
        ) : null}
        <Select
          // dropdownStyle={{
          //   background: "var(--select-background)",
          //   color: "var(--text-color)",
          // }}

          value={value?.toString()}
          defaultValue={defaultValue}
          style={{ width: width || 120 }}
          onChange={changeHandler}
          // listHeight={300}
          options={
            allowClear
              ? [
                  { value: 10000, label: "Не выбрано" },
                  ...options.filter((o: any) => o.value !== 10000),
                ]
              : options
          }
        />
      </Space>
  );
};
