import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/plugins/store/store.hooks";
import styles from "./geo.module.css";

let autoSlideInterval: any;

let slide = 0;

function startAutoSlide(setSlide: any) {
  if (!autoSlideInterval) {
    autoSlideInterval = setInterval(() => {
      slide = slide + 1;
      setSlide(slide);
    }, 2000);
  }
}

function stopAutoSlide() {
  clearInterval(autoSlideInterval);
  autoSlideInterval = undefined;
}

export const Geo = () => {

  useEffect(() => {
    startAutoSlide(setSlide);
    return () => {
      stopAutoSlide();
    };
  }, []);

  const [slide, setSlide] = useState(1);

  useEffect(() => {
    nextSlide(true);
  }, [slide]);

  const { t } = useTranslation();
  const breakpoint = useAppSelector(state=>state.adaptiveData.breakpoint)
  const [currentSlide, setCurrentSlide] = useState(1);
  const prevSlide = () => {
    stopAutoSlide();
    if (currentSlide === 1) {
      setCurrentSlide(3);
    } else {
      setCurrentSlide(prev=>(prev - 1));
    }
  }

  const nextSlide = (isAuto?: boolean) => {
    if (!isAuto) stopAutoSlide();
    if (currentSlide === 3) {
      setCurrentSlide(1);
    } else {
      setCurrentSlide(prev=>(prev + 1));
    }
  }

  useEffect(()=>{
    console.log(breakpoint);
  },[breakpoint])
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <p>{t('geo.located_in')}</p> <strong>{t('geo.country_1')}</strong><p>,</p>{" "}
        <strong>{t('geo.country_2')}</strong><p>,</p> <strong>{t('geo.country_3')}</strong> <p>{t('geo.and')}</p> <strong>{t('geo.country_4')}</strong>
      </div>
      <div className={styles.items}>
        {breakpoint !== 'xs' ? <><div className={styles.item} key={3}>
          <img className={styles.svg} src={`./images/representatives/3.png`} />
          <img
            className={`${styles.border} ${styles.border_normal}`}
            src={`./images/representatives/border_3.svg`}
          />
          <img
            className={styles.arrow}
            src={`./images/representatives/arrow_3_1.svg`}
          />
          {/* <div className={styles.itemDescription}>
            <p className={styles.text}>
              {t(`representatives.items.item_3.text`)}
            </p>
            <p className={styles.subtext}>
              more than <strong>400</strong> partner
            </p>
          </div> */}
        </div>
        <div className={styles.item} key={1}>
          <img className={styles.svg} src={`./images/representatives/1.png`} />
          <img
            className={`${styles.border} ${styles.border_reverted}`}
            src={`./images/representatives/border_1.svg`}
          />
          <img
            className={styles.arrow}
            src={`./images/representatives/arrow_1_1.svg`}
          />
          {/* <img
            className={`${styles.arrow} ${styles.arrow_1_2}`}
            src={`./images/representatives/arrow_1_2.svg`}
          /> */}
          {/* <div className={styles.itemDescription}>
            <p className={styles.text}>
              {t(`representatives.items.item_1.text`)}
            </p>
            <p className={styles.subtext}>
              more than <strong>200</strong> partners
            </p>
          </div> */}
        </div>
        <div className={styles.item} key={2}>
          <img className={styles.svg} src={`./images/representatives/2.png`} />
          <img
            className={`${styles.border} ${styles.border_normal}`}
            src={`./images/representatives/border_2.svg`}
          />
          <img
            className={styles.arrow}
            src={`./images/representatives/arrow_2_1.svg`}
          />
          {/* <div className={styles.itemDescription}>
            <p className={styles.text}>
              {t(`representatives.items.item_2.text`)}
            </p>
            <p className={styles.subtext}>
              <strong>8000</strong> TEU
            </p>
          </div> */}
        </div></> : <div className={styles.slider}>
        
        <img
            className={`${styles.sliderArrow} ${styles.arrowLeft}`}
            onClick={prevSlide}
            src={`./images/representatives/mobile_arrow.svg`}
          />

          <img className={styles.svg} src={`./images/representatives/${currentSlide}.png`} />
          <img
            className={`${styles.border} ${styles.border_normal}`}
            src={`./images/representatives/border_${currentSlide}.svg`}
          />
          <img
            className={styles.arrow}
            src={`./images/representatives/arrow_${currentSlide}_1.svg`}
          />
          <img
              className={`${styles.sliderArrow} ${styles.arrowRight}`}
              onClick={()=>{nextSlide()}}
              src={`./images/representatives/mobile_arrow.svg`}
            />
        </div>}
        
      </div>
      <div className={styles.description}>
        <p>
        {t('geo.description')}
        </p>
      </div>
    </div>
  );
};
