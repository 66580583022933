import { BreakpointsEnum, IAdaptiveState } from "./adaptive.types";

export const adaptiveInitialState: IAdaptiveState = {
  breakpoint: BreakpointsEnum.lg,
  maxWidths: {
    lg: Infinity,
    md: 1200,
    sm: 992,
    xs: 768
  },
};
