import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./logistics.module.css";

export const Logistics :FC<{scrollRef: any}> = ({scrollRef}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container} ref={scrollRef}>
      <div className={styles.title}>
        <p>{t('logistics.title')}</p>
      </div>
      <div className={styles.images}>
        <div className={styles.image}>
          <div className={styles.image_movable}>
            <img src="images/logistics/european.jpg" />
            <div className={styles.line}/>
            <p>{t('logistics.european')}</p>
          </div>
        </div>

        <div className={styles.image}>
          <div className={styles.image_movable}>
            <img src="images/logistics/china.jpg" />
            <div className={styles.line}/>
            <p>{t('logistics.china')}</p>
          </div>
        </div>

        <div className={styles.image}>
          <div className={styles.image_movable}>
            <img src="images/logistics/usa.jpg" />
            <div className={styles.line}/>
            <p>{t('logistics.usa')}</p>
          </div>
        </div>

        <div className={styles.image}>
          <div className={styles.image_movable}>
            <img src="images/logistics/turkey.jpg" />
            <div className={styles.line}/>
            <p>{t('logistics.turkey')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
