import i18next from "i18next";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./contacts.module.css";
import { HomeFilled, PhoneFilled, MailFilled } from "@ant-design/icons";

const list = [1, 2, 3];

export const Contacts = () => {
  const { t } = useTranslation();

  const socials = useMemo(() => {
    return [
      {
        src: "/images/contacts/fb.svg",
        link: t("footer.facebook_url"),
      },
      {
        src: "/images/contacts/twitter.svg",
        link: t("footer.twitter_url"),
      },
      {
        src: "/images/contacts/inst.svg",
        link: t("footer.instagram_url"),
      },
      {
        src: "/images/contacts/linked.svg",
        link: t("footer.linked_url"),
      },
    ];
  }, [i18next.language]);

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.logo}>
          <img src="./images/home/logo.png" />
          <div className={styles.tag}>
            <p>{t("logo.subtitle")}</p>
          </div>
        </div>
        <div className={styles.socials}>
          {socials.map((social) => (
            <div>
              <a target={"_blank"} href={social.link}>
                <img src={social.src} />
              </a>
            </div>
          ))}
        </div>
        <div className={styles.contacts}>
          <div className={styles.list}>
            <p className={styles.address_title}>{t("footer.description")}</p>
            <div className={styles.item} key={1}>
              <p className={styles.key}>
                <HomeFilled />
              </p>
              <p className={styles.value}>{t("footer.address_value")}</p>
            </div>
            <div className={styles.item} key={2}>
              <p className={styles.key}>
                <PhoneFilled />
              </p>
              <a href={`tel:${t("footer.phone_value")}`}><p className={styles.value}>{t("footer.phone_value")}</p></a>
              <a
                target={"_blank"}
                href={`https://wa.me/${t("contacts.spain.phone_value")
                  ?.replaceAll("+", "")
                  .replaceAll(" ", "")}`}
              >
                <img src="images/contacts/whatsapp.svg" />
              </a>
            </div>
            <div className={styles.item} key={3}>
              <p className={styles.key}>
                <MailFilled />
              </p>
              <p className={styles.value}>{t("footer.email_value")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
